@import 'src/client/application/variables.sass'
@import '@ahmdigital/ui/src/utils/constants/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/rem.module.sass'
.horizontalRule
  border-bottom: 0
  border-left: 0
  border-right: 0
  border-top: 3px dashed $color-white
  margin-bottom: 3em
  margin-top: 0.25em
