.fadeEnter,
.fadeAppear
  opacity: 0

.fadeEnterActive,
.fadeAppearActive
  opacity: 1
  transition-delay: 200ms
  transition-duration: 400ms
  transition-property: opacity
  transition-timing-function: cubic-bezier($ease-out-cubic)

.fadeLeave
  opacity: 1
  transition-duration: 400ms
  transition-property: opacity
  transition-timing-function: cubic-bezier($ease-out-cubic)

.fadeLeaveActive
  opacity: 0

.fadeToggleLeave,
.fadeToggleLeaveActive
  display: none
