// http://meyerweb.com/eric/tools/css/reset
$color-black-full-transparent: rgba(0, 0, 0, 0)

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video
  border: 0
  font: inherit
  font-size: 100%
  margin: 0
  padding: 0
  vertical-align: baseline

// HTML5 display-role reset for older browsers
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section
  display: block

body
  line-height: 1

ol,
ul
  list-style: none

blockquote,
q
  quotes: none

  &::before,
  &::after
    content: ''

table
  border-collapse: collapse
  border-spacing: 0

// Box Sizing - http://www.paulirish.com/2012/box-sizing-border-box-ftw
html
  box-sizing: border-box

*,
*::before,
*::after
  box-sizing: inherit

// Responsive Images
img
  max-width: 100%

// For various 100% height elements.
html,
body
  height: 100%

// Remove tap colour on phones - http://phonegap-tips.com/articles/essential-phonegap-css-webkit-tap-highlight-color.html
a
  -webkit-tap-highlight-color: $color-black-full-transparent // sass-lint:disable-line no-vendor-prefixes
