@import 'src/client/application/variables.sass'
@import '@ahmdigital/ui/src/utils/constants/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/rem.module.sass'
.footerLinks
  align-items: flex-end
  bottom: 0
  display: flex
  max-width: 1440px
  pointer-events: none
  position: fixed
  transition: bottom 0.3s
  width: 100%
  z-index: $z-index-footer-links

  > div
    pointer-events: all

.liveChat
  margin-left: 10px
  max-width: 25vh

.autoLeft
  margin-left: auto

.choosablePicker
  bottom: 100px
  transition: bottom 0.3s

  +above($screen-sm)
    bottom: 120px

  +above($screen-md)
    bottom: 110px
