.slideInEnter,
.slideInAppear
  opacity: 0
  transform: translateY(-0.5em)

.slideInEnterActive,
.slideInAppearActive
  opacity: 1
  transform: translateY(0)
  transition-delay: 200ms
  transition-duration: 400ms
  transition-property: opacity transform
  transition-timing-function: cubic-bezier($ease-out-cubic)

.slideInLeave
  opacity: 1
  transform: translateY(0)
  transition-duration: 400ms
  transition-property: opacity transform
  transition-timing-function: cubic-bezier($ease-out-cubic)

.slideInLeaveActive
  opacity: 0
  transform: translateY(-0.5em)

.slideInToggleLeave,
.slideInToggleLeaveActive
  display: none
