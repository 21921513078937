.overlayFadeEnter
  opacity: 0 !important // sass-lint:disable-line no-important

.overlayFadeEnterActive
  opacity: 0.8 !important // sass-lint:disable-line no-important
  transition-delay: 100ms
  transition-duration: 300ms
  transition-property: opacity
  transition-timing-function: cubic-bezier($ease-out-cubic)

.overlayFadeLeave
  opacity: 0.8 !important // sass-lint:disable-line no-important
  transition-delay: 400ms
  transition-duration: 300ms
  transition-property: opacity
  transition-timing-function: cubic-bezier($ease-out-cubic)

.overlayFadeLeaveActive
  opacity: 0 !important // sass-lint:disable-line no-important

.modalSlideInEnter
  opacity: 0
  transform: translateY(-200px)

.modalSlideInEnterActive
  opacity: 1
  transform: translateY(0)
  transition-delay: 300ms
  transition-duration: 300ms
  transition-property: opacity
  transition-timing-function: cubic-bezier($ease-out-cubic)

.modalSlideInLeave
  opacity: 1
  transform: translateY(0)
  transition-duration: 300ms
  transition-property: opacity
  transition-timing-function: cubic-bezier($ease-out-cubic)

.modalSlideInLeaveActive
  opacity: 0
  transform: translateY(-200px)
