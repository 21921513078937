table
  width: 100%

  tr
    background-color: $color-grey-60
    transition: 0.3s ease background-color

    &:hover
      background-color: $color-grey-70

  th,
  td
    border-bottom: 2px solid $color-black
    font-size: $base-font-size-small
    padding: 12px 15px
    text-align: center
    vertical-align: middle

    &:first-child
      text-align: left

    sub
      color: $color-grey-30
      display: block
      font-size: 10px

  td
    cursor: pointer

  th
    background-color: $color-grey-70

    &:first-child
      font-size: 24px
      font-weight: $font-weight-bold
