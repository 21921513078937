.shakeEnter,
.shakeAppear
  opacity: 0

.shakeEnterActive,
.shakeAppearActive
  animation-duration: 500ms
  animation-fill-mode: both
  animation-name: shake
  opacity: 1
  transition-delay: 200ms
  transition-duration: 500ms
  transition-property: opacity
  transition-timing-function: cubic-bezier($ease-out-cubic)

.shakeLeave
  opacity: 1
  transition-duration: 300ms
  transition-property: opacity
  transition-timing-function: cubic-bezier($ease-out-cubic)

.shakeLeaveActive
  opacity: 0

.shakeToggleLeave,
.shakeToggleLeaveActive
  display: none

@keyframes shake
  from,
  to
    transform: translate3d(0, 0, 0)

  10%,
  40%
    transform: translate3d(-5px, 0, 0)

  25%,
  55%
    transform: translate3d(5px, 0, 0)

  70%
    transform: translate3d(-3px, 0, 0)

  85%
    transform: translate3d(3px, 0, 0)
