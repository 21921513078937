@import 'src/client/application/variables.sass'
@import '@ahmdigital/ui/src/utils/constants/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/rem.module.sass'
.container
  background-color: $color-grey-40
  color: $color-black
  font-size: 0.75rem
  left: 0
  padding: 0.5rem
  position: absolute
  top: 0

.dataset
  font-weight: 600

.mode
  border-radius: 0.25rem
  color: $color-white
  font-weight: 500
  margin-left: 0.5rem
  padding: 0.25rem 0.5rem

.production
  background-color: $color-purple-tint

.staging
  background-color: $color-blue-tint

.draft
  background-color: $color-orange

.published
  background-color: $color-green-dark
