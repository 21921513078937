@import 'src/client/application/variables.sass'
@import '@ahmdigital/ui/src/utils/constants/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/rem.module.sass'
.logoImage
  display: block
  min-width: 10.25rem

  +below($screen-sm)
    height: 4rem
    min-width: auto
    padding: 0.25rem 0
    width: 8.25rem

.loginLink
  display: inline-block
  margin-right: 1rem
  white-space: nowrap

  +above($screen-sm)
    margin-right: 0
