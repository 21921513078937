@import 'src/client/application/variables.sass'
@import '@ahmdigital/ui/src/utils/constants/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/rem.module.sass'
$color-black-half-transparent: rgba(0, 0, 0, 0.5)
$drop-shadow-color: rgba(0, 0, 0, 0.12)

.active
  animation-duration: 0.8s
  animation-fill-mode: both
  animation-name: toaster

.captured
  bottom: -20px
  padding-right: 35px

.contactUs
  font-weight: $font-weight-semibold

.dismiss
  color: $color-grey-20
  font-size: rem(20px)
  position: absolute
  right: 15px
  top: 10px

.form
  background-color: $color-white
  box-shadow: 0 2px 10px 0 $color-black-half-transparent
  color: $color-text-black
  max-height: 100vh
  overflow: auto
  padding: 2.25rem 1rem 1rem
  position: relative
  width: 100%

  @include above($screen-sm)
    height: auto
    max-height: 45rem
    padding: 2.25rem 1.25rem 1.25rem

.image
  filter: drop-shadow($drop-shadow-color 12px 12px 12px)
  max-width: 25vh

.minimizedButton
  margin-bottom: 0.5rem
  z-index: $z-index-live-chat

  @include below($screen-md)
    position: fixed
    right: 0
    top: 50%
    transform: rotate(-0.25turn) translate(70%)
    transform-origin: bottom right

.visuallyHidden
  clip: rect(1px, 1px, 1px, 1px)
  height: 1px
  overflow: hidden
  position: absolute
  width: 1px

.wrapperInner
  margin: 0 auto
  max-width: 23.5rem

.wrapperOuter
  bottom: 0
  z-index: $z-index-request-a-call
