html
  font-size: $base-font-size

body
  color: $color-text-black
  font-family: $base-font-family
  font-weight: $font-weight-regular
  line-height: 1.5
  text-rendering: optimizeLegibility

h1,
h2,
h3,
h4,
h5,
h6,
strong
  font-weight: $font-weight-bold

h1
  font-size: rem(64px)
  line-height: 1
  margin-bottom: rem(10px)

  @include below($screen-sm)
    font-size: rem(40px)

h2
  font-size: rem(48px)
  line-height: 1.05
  margin-bottom: rem(10px)

  @include below($screen-sm)
    font-size: rem(30px)

h3
  font-size: rem(32px)
  line-height: 1.1
  margin-bottom: rem(5px)

  @include below($screen-sm)
    font-size: rem(24px)

h4
  font-size: rem(24px)
  line-height: 1.25
  margin-bottom: rem(5px)

  @include below($screen-sm)
    font-size: rem(20px)

h5
  font-size: rem(20px)
  line-height: 1.3
  margin-bottom: rem(5px)

  @include below($screen-sm)
    font-size: rem(18px)

h6
  font-size: rem(16px)
  line-height: 1.4
  margin-bottom: rem(5px)

p
  margin-bottom: rem(16px)

  &:last-child
    margin-bottom: 0
