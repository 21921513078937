@import 'src/client/application/variables.sass'
@import '@ahmdigital/ui/src/utils/constants/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/rem.module.sass'
$drop-shadow-color: rgba(0, 0, 0, 0.12)

@keyframes toaster
  from,
  60%,
  75%,
  90%,
  to
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)

  from
    opacity: 0
    transform: translateY(900px)

  60%
    opacity: 1
    transform: translateY(-20px)

  75%
    transform: translateY(10px)

  90%
    transform: translateY(-5px)

  to
    transform: translateY(0)


.button
  width: 100%

.checkbox
  input
    accent-color: $color-purple

.field
  margin-bottom: 1rem

.fieldWithSuffixText
  p
    color: $color-grey-50
