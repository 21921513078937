@import '../variables'

body
  background-color: $color-black
  color: $color-text-white
  min-width: 367px // Fixes a black line appearing on any device smaller than 366px
  overflow-x: hidden

#__next // sass-lint:disable-line no-ids
  background-color: $color-white

\:global
  #content // sass-lint:disable-line no-ids
    background-color: $color-white
