// Typography and Box Model
$base-font-family: proxima-nova, 'Helvetica Neue', Helvetica Arial, sans-serif
$base-font-size: 16px
$base-font-size-small: 14px
$radius: 3px
$dashed-border-width: 4px
$input-min-width: 18rem
$input-size-sm: rem(160px)
$input-size-md: rem(320px)
$input-size-lg: rem(480px)
$input-size-xl: rem(544px)
$font-weight-regular: 400
$font-weight-semibold: 600
$font-weight-bold: 700
$product-panel-gutter: 8px
$section-spacing: rem(60px)

// Colors
$color-black: #000
$color-blue: #2598e6
$color-blue-dark: #1561e6
$color-blue-tint: #edf3f6
$color-green: #0a5
$color-green-dark: #308654
$color-green-tint: #ecf6ed
$color-magenta: #bb2fb2
$color-orange: #ec8e00
$color-purple: #9855d4
$color-purple-dark: #734098
$color-purple-tint: #fcf5ff
$color-red: #e62525
$color-red-dark: #b50002
$color-red-tint: #fcf2f2
$color-white: #fff
$color-text-black: $color-black
$color-text-white: $color-white
$color-grey-10: #f6f6f6
$color-grey-20: #ccc
$color-grey-30: #aaa
$color-grey-40: #888
$color-grey-50: #666
$color-grey-60: #444
$color-grey-70: #222
$ease-out-cubic: 0.215 0.61 0.355 1

// Z-Indices
$z-index-buy-summary: 100
$z-index-date-picker: 200
$z-index-dropdown: 300
$z-index-overlay: 500
$z-index-footer: 700
$z-index-zoom-panel: 900
$z-index-header: 1000
$z-index-affix: 1100
$z-index-segmentation-bar: 1200
$z-index-dropdown-menu: 1250
$z-index-feedback: 1700
$z-index-live-chat-invite: 1800
$z-index-request-a-call: 1300
$z-index-tooltip: 1900
$z-index-live-chat: 2000
$z-index-modal: 2300
$z-index-footer-links: 2300

// Grid
$grid-columns: 12
$grid-gutter: 30px
$wrapper-width: 960px

// Offer banner
$circle-size: 122px
$circle-size-tablet: 81px
$circle-size-mobile: 73px

@mixin clearfix
  &::before,
  &::after
    content: ' '
    display: table

  &::after
    clear: both
    display: block
    height: 1px
    margin-top: -1px
    visibility: hidden

// sass-lint:disable empty-line-between-blocks
\:export
  // sass-lint:disable no-misspelled-properties
  baseFontFamily: $base-font-family
  baseFontSize: $base-font-size
  baseFontSizeSmall: $base-font-size-small
  black: $color-black
  blue: $color-blue
  blueDark: $color-blue-dark
  blueTink: $color-blue-tint
  dashedBorderWidth: $dashed-border-width
  fontWeightBold: $font-weight-bold
  fontWeightRegular: $font-weight-regular
  green: $color-green
  greenDark: $color-green-dark
  greenTint: $color-green-tint
  grey10: $color-grey-10
  grey20: $color-grey-20
  grey30: $color-grey-30
  grey40: $color-grey-40
  grey50: $color-grey-50
  grey60: $color-grey-60
  grey70: $color-grey-70
  inputMinWidth: $input-min-width
  inputSizeLg: $input-size-lg
  inputSizeMd: $input-size-md
  inputSizeSm: $input-size-sm
  inputSizeXl: $input-size-xl
  magenta: $color-magenta
  orange: $color-orange
  productPanelGutter: $product-panel-gutter
  purple: $color-purple
  purpleDark: $color-purple-dark
  purpleTint: $color-purple-tint
  radius: $radius
  red: $color-red
  redDark: $color-red-dark
  redTint: $color-red-tint
  sectionSpacing: $section-spacing
  textBlack: $color-text-black
  textWhite: $color-text-white
  white: $color-white
  zIndexFooter: $z-index-footer
  // sass-lint:enable no-misspelled-properties
// sass-lint:enable empty-line-between-blocks
